<template>
  <div class="col ms-2 ms-md-0 person-col border-bottom pt-2 p-3"  v-if="!person.isLetter">
    <div :to="'/app/persons/' + person.id" class="card h-100 person-item">
      <div class="position-absolute bottom-0 end-0">
        <FavoriteButton :fav-id="'persons/' + person.id" v-if="person.id" is-list="true" ></FavoriteButton>
      </div>


      <router-link :to="'/app/persons/' + person.id" class="row g-0 h-100 person-item">
        <!--div class="col-1 col-md-12 d-flex align-items-center p-0 p-md-0 pb-md-1">
          <img class="img-fluid rounded person-image" v-if="!person.vitae_picture" src="assets/icon/avatar.png">
          <img class="img-fluid rounded person-image" v-if="person.vitae_picture"
               :src="'https://fileserver-188ab.kxcdn.com/file/' + person.vitae_picture.split('/')[4]">

        </div-->
        <div class="col-11 col-md-12 mt-2">
          <div class="card-body py-0  ps-md-2 mt-2 mt-md-0 ">
            <p class="card-title mt-2 mb-0 mt-md-0 mb-md-0 fw-bold">{{ person.display_name }}</p>
             <!--p v-if="person.institution_1" class="card-text pb-0 pb-md-2 mb-0">{{person.institution_1}} </p-->
               <p v-if="person.institution_2" class="card-text pb-0 pb-md-2 mb-0">{{person.institution_2}} </p>
                
                <!--p class="pb-0 pb-md-2 mb-0"><span v-if="person.city">{{ person.city }}, </span> {{ person.country }} </p-->
            <p class="card-text pt-0 mt-0 pb-md-1"><span class="text-muted" v-if="person.function">{{
                person.function
              }}</span>
            </p>

             <div class="speaker-line small" v-if="person.note">My Note:
                    <em>&quot;{{ person.note }}&quot;</em>
            </div>
          </div>
        </div>
      </router-link>
    </div>

  </div>
  <div class="col-12" v-if="person.isLetter" style="width:100vw; padding-left:15px">
    <h3 class="my-0">{{ person.letter }}</h3>
  </div>

</template>

<script>
import FavoriteButton from "@/components/favorite/FavoriteButton";

export default {
  data() {
    return {}
  },
  components:{FavoriteButton},
  props: ['person'],

};
</script>
<style lang="scss">
.person-col{
  max-width: 100%;
}
.person-item {
  text-decoration: none;
  //max-width:200px;
  color: #000;
  width: 100%;
  border:none;
  //box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 18%) !important;

  .card-body {
    font-size: 0.9rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    .card-title {
      line-height: 1rem;
    }
  }

   .card-text {
      max-width: 85%;
      color:grey;
    }

  .person-image {
    object-fit: cover;
    object-position: top;
    aspect-ratio: 1/1;
    min-width: 50px;
  }
}


@media(max-width: 767px) {
  .person-col{
    max-width:  100%;
  }
  .person-item {
    max-width: 100%;
    width: 100%;
    border-radius: 0;
    border: none;
    box-shadow: none !important;
    .person-image {
      object-fit: cover;
      object-position: top;
      aspect-ratio: 1/1;
      width: 50px;
    }
  }
}
</style>
