<template>
  <base-layout>

    <ion-content class=" has-header has-subheader has-bottom-banner" id="wcpt-session-detail">
    <div class="session-head mb-3">
      <div class="card border-0  h-100 ">
        <div class="card-body pt-2 pb-0 px-4 mx-2" v-if="sessiontype">
          <strong class="title pb-1 d-block ">
            <span v-if="session.code"> {{ session.code }} - </span>
            <span v-html="session.title"></span>
          </strong>
          <div class="time " v-if="sessiontype">
            {{ moment(session.start_time).format('dddd HH:mm') }} -
            {{ moment(session.end_time).format('HH:mm') }} {{ timezoneString }}
            <CalendarButton :session="session" :utcOffset="utcOffset" v-if="session.id && isApp "></CalendarButton>
          </div>
           
          <div class="time" v-if="sessiontype">
            <router-link class="d-block " style="text-decoration:none"
                         :to="'/app/sessiontypes/' + sessiontype.id">
              <span class="d-inline-block mt-1 location-text">
                 {{ sessiontype.name }}
              </span>
            </router-link>

            <router-link class="d-block " style="text-decoration:none"
                         :to="'/app/locationmap/' + session.location.id"
                         v-if="session.location">
              <span class="me-1 location-icon" v-if="mapAvailable">
                <font-awesome-icon :icon="['far', 'map-marker-alt']"/>
              </span>
              <span class="d-inline-block mt-1 location-text">
               {{ session.location.name }}
              </span>
            </router-link>
          </div>
        </div>
        <div class="code-wrapper mx-3 mt-2 py-4"
             :style="{'color': session.font_color, 'background-color':session.color}">
        </div>
      </div>


      <div class="session-corner-annotation" v-if="session.is_ted">
        <div class="annotation-text"> TED</div>
      </div>
    </div>


     
      <div v-if="session && session.outline">
            <div class="description-box mt-1 mb-2" style="padding: 20px; padding-top:5px;" v-html="session.outline"></div>
            <button type="button" class="description-button btn btn-sm btn-secondary py-0" data-bs-toggle="modal"
                    data-bs-target="#descriptionModal">
             {{wordings[currentLanguage].OPEN_DESCRIPTION}}
            </button>
            <div :style="{'margin-top': isIos ? '50px' : '0px'}" class="modal fade  py-5" id="descriptionModal" tabindex="-1" data-bs-backdrop="false" aria-labelledby="descriptionModalLabel" aria-hidden="true">
              <div class="modal-dialog  modal-fullscreen-sm-down modal-dialog-centered  modal-dialog-scrollable">
                <div class="modal-content note-modal">
                  <div class="modal-header">
                    <h5 class="modal-title" id="descriptionModalLabel"> {{wordings[currentLanguage].SESSION_DESCRIPTION}}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body" v-html="session.outline">
                  </div>
                </div>
              </div>
            </div>

          </div>

      <div v-if="session">
        <h3 style="padding:10px;color:red" v-if="isRestrictedAccess">{{
            wordings[currentLanguage].SESSION_NO_ACCESS
          }}</h3>
        <div class="details-container">
          <div class="container-fluid ">
            <div class="row justify-content-center justify-content-md-start px-2 mt-3">
              <div class="col px-2" style="max-width: 200px;">
                <FavoriteButton :fav-id="favId" v-if="session.id"></FavoriteButton>
              </div>
              <div class="col px-2" style="max-width: 200px;">
                <NoteButton :fav-id="favId" v-if="session.id"></NoteButton>
              </div>
              <div class="col px-1 d-none d-md-block" v-if="polls && polls.length > 0">
                <a
                    class="btn btn-secondary opacity-75 d-block px-2 px-md-1" style="text-decoration: none;font-size: 0.9rem"
                    v-for="poll in polls" :key="poll.id"
                    :href="'#/app/polls/' + poll.id + '/sessions/' + session.id"
                >
                  <font-awesome-icon :icon="['fas', 'poll']"/>
                  {{ wordings[currentLanguage].EVALUATE }}
                </a>
              </div>
            </div>
            
            <div class="col px-1 d-none d-md-block mt-3 " v-if="activeSessions && activeSessions.indexOf(session.id) != -1" style="max-width: 200px">
                  <a
                      class="btn btn-secondary opacity-75 d-block px-2 px-md-1"
                      style="text-decoration: none;font-size: 0.9rem ;max-width: 200px; background: #E30513; border-color: #E30513"
                      :href="'#/app/liveroom/' + session.location.id "
                  >
                    <font-awesome-icon :icon="['fas', 'camcorder']"/>
                    Live ansehen
                  </a>
                </div>

                <div class="col px-1 d-md-none mb-1 mt-2 mx-3" v-if="activeSessions && activeSessions.indexOf(session.id) != -1">
                <a
                    class="btn btn-secondary opacity-75 d-block px-2 px-md-1"
                    style="text-decoration: none;font-size: 0.9rem; background: #E30513; border-color: #E30513"
                    :href="'#/app/liveroom/' + session.location.id "
                >
                  <font-awesome-icon :icon="['fas', 'camcorder']"/>
                  Live ansehen
                </a>
              </div>



            <div class="row my-2 px-2 d-md-none" v-if="polls && polls.length > 0">
              <div class="col px-2 mx-auto">
                <a
                    class="btn btn-secondary opacity-75 d-block px-2 px-md-1" style="text-decoration: none; font-size: 0.9rem"
                    v-for="poll in polls" :key="poll.id"
                    :href="'#/app/polls/' + poll.id + '/sessions/' + session.id"
                >
                  <font-awesome-icon :icon="['fas', 'poll']"/>
                  {{ wordings[currentLanguage].EVALUATE }}
                </a>
              </div>
            </div>
          </div>


        </div>
        <div class="container-fluid mt-2">
          <div class="row row-cols-1  justify-content-start" :class="{'row-cols-md-2': qandaVisible || votingVisible}">
            <div class="col-12  px-0 content-left" :class="{'col-md-8': qandaVisible || votingVisible}">
              <div class="card border-0">
                <div class="card-body py-0">

                  <!--div class="stream-container "
                       v-if="((session.liveLink && liveSessionActive)  || activeSessions.indexOf(session.id) != -1)  && user.typeName && user.typeName.length > 0">
                    <div class="stream">
                      <iframe
                          v-if="((isLiveSession && liveSessionActive) || activeSessions.indexOf(session.id) != -1 )"
                          :src="session.liveLink"
                          style="position:absolute;top:0;left:0;width:100%;height:100%;"
                          frameborder="0"
                          allow="autoplay; fullscreen"
                          allowfullscreen
                      ></iframe>
                    </div>
                  </div-->
                  <div class="stream-container"
                       v-if="session.odLink  &&  (!liveSessionActive)  && user.typeName && user.typeName.length > 0"
                  >
                    <div class="stream">
                      <iframe
                          v-if="session.odLink"
                          :src="session.odLink"
                          style="position:absolute;top:0;left:0;width:100%;height:100%;"
                          frameborder="0"
                          allow="autoplay; fullscreen"
                          allowfullscreen
                      ></iframe>
                    </div>

                  </div>
                </div>
              </div>
              <div class="d-md-none">
                <user-view
                    :session-id="session.id"
                    v-if="loaded"
                    style="width: 100%;  display:inline-block; vertical-align: top;overflow: hidden;overflow-x:hidden;"
                ></user-view>
              </div>

              <IonSegment :value="showTab" class="interactive-segment mb-2" style="border-bottom: 1px solid lightgrey;">
                <IonSegmentButton v-if="this.persons.length>0" class="interactive-segment-btn"
                                  value="Moderation"
                                  @click="this.showTab = 'Moderation'"
                >
                  <IonLabel>{{ wordings[currentLanguage].CHAIR_HEADER }}</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton v-if="this.presentations.length>0" class="interactive-segment-btn"
                                  value="Presentations"
                                  @click="this.showTab = 'Presentations'"
                >
                  <IonLabel>{{ wordings[currentLanguage].PRESENTATION_HEADER }}</IonLabel>
                </IonSegmentButton>
              </IonSegment>

              <div class="card border-0">
                <div class="container-fluid p-0 px-2 mt-md-2">
                  <div v-if="showTab == 'Moderation'">
                    <div
                        class="row row-cols-1 row-cols-md-4 g-2 ps-md-2 g-md-3 row-eq-height justify-content-start">
                      <template v-for="person in persons" :key="person.id">
                        <person-item :person="person"></person-item>
                      </template>
                    </div>
                  </div>
                  <div v-if="showTab == 'Presentations'">
                    <presentation-list :presentations="presentations" hide-time="hideTime"></presentation-list>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12  d-none d-md-NONE px-0" :class="{'col-md-4': qandaVisible || votingVisible}">
              <div class="card border-0">
                <div class="card-body p-0 m-0">
                  <IonSegment :value="activeTab" class="interactive-segment">
                    <IonSegmentButton class="interactive-segment-btn"
                                      value="interaction"
                                      @click="changeTab('interaction')" v-if="!liveStreamHasEnded"
                    >
                      <IonLabel>{{ wordings[currentLanguage].INTERACTIVE }}</IonLabel>
                    </IonSegmentButton>
                  </IonSegment>
                  <div>
                    <user-view v-on:qanda-active="toggleQanda" v-on:voting-active="toggleVoting"
                               :session-id="session.id"
                               v-if="loaded"
                               style="width: 100%;  display:inline-block; vertical-align: top;overflow: hidden;overflow-x:hidden;"
                    ></user-view>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </base-layout>
</template>

<script>
import {IonContent, IonSegment, IonSegmentButton} from "@ionic/vue";
import {defineComponent} from "vue";
import {mapActions, mapGetters, mapState} from "vuex";
import moment from "moment";
import PresentationList from "@/components/PresentationList";
import UserView from "@/components/interactive/UserView";
const config = require('@/config');
//import Forum from "@/components/interactive/Forum";
import FavoriteButton from "@/components/favorite/FavoriteButton";
import CalendarButton from "@/components/CalendarButton";

import NoteButton from "@/components/favorite/NoteButton";
import PersonItem from "@/components/PersonItem";
//moment.locale("de");
import {isPlatform} from '@ionic/vue';

export default defineComponent({
  name: "SessionDetail",
  data() {
    return {
      session: {},
      loaded: false,
      isLiveSession: false,
      persons: [],
      speakers: [],
      presentations: [],
      activeTab: "interaction",
      activeSection: 'details',
      interval: null,
      sessionHasStarted: false,
      sessionHasEnded: false,
      liveStreamHasStarted: false,
      liveStreamHasEnded: false,
      liveSessionActive: false,
      zoomSessionActive: false,
      actualTime: "",
      sessiontype: null,
      favId: null,
      mapAvailable: false,
      isRestrictedAccess: false,
      qandaVisible: false,
      votingVisible: false,
      showTab: 'Moderation',
      utcOffset: '+01:00',
      timezoneString: 'CET',
      polls: [],
      isApp : config.is_app,
    };
  },
  components: {
    IonContent,
    "presentation-list": PresentationList,
    "user-view": UserView,
    /*forum: Forum,*/
    FavoriteButton,
    IonSegment,
    IonSegmentButton,
    NoteButton,
    PersonItem,
    CalendarButton

  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapState('sessions', ['activeSessions']),
    ...mapState('wordings', ['wordings', 'currentLanguage']),
    ...mapState('currentConference', ['currentConference', 'fullName', 'shortName', 'apiVersion']),
  },
  methods: {
    ...mapActions("sessions", [
      "getSingleSession",
      "getPresentationList",
      "getSpeakersInSession",
      "getSessionPersons",
      "getSessionType"
    ]),
    ...mapActions("settings", [
      "getSetting",
    ]),
    ...mapActions("video", ["stopVideos"]),
    ...mapActions('googleanalytics', ['trackWithLabel']),

    ...mapActions("maps", ["getMapFromLocation"]),
    ...mapActions("cmetracking", [
      "uploadTrackingEvents",
      "trackEvent",
    ]),
    ...mapActions("polls", [
      "getPollForState",
    ]),
    toggleQanda(status) {
      this.qandaVisible = status;
    },
    toggleVoting(status) {
      this.votingVisible = status;
    },

    async getServerTime() {
      try {
        let time_url =
            "https://mantel-api.mobile.documedias.systems/v2/timestamp";
        return (await this.axios.get(time_url)).data;
      } catch (err) {
        console.log(err);
        return null;
      }
    },

    changeTab(section) {
      this.activeTab = section;
    },

    getForum(refId) {
      this.$socketInt.emit("getForum", {
        congressId: this.currentConference,
        refId: refId
      });
    },
    checkTime(session) {
      let dateNow;
      let sessionStart;
      let sessionEnd;
      let liveSessionStart;
      let liveSessionEnd;
      let zoomSessionEnd;
      dateNow = new Date(moment.utc());//new Date(this.actualTime);
      sessionStart = new Date(session.start_time + this.utcOffset);
      sessionEnd = new Date(session.end_time + this.utcOffset);

      this.sessionHasStarted = dateNow > sessionStart;
      this.sessionHasEnded = dateNow > sessionEnd;
      liveSessionStart = moment(sessionStart).subtract(5, "minutes");
      liveSessionEnd = moment(sessionEnd).add(10, "minutes");
      zoomSessionEnd = moment(sessionEnd).add(10, "minutes");
      this.liveStreamHasStarted = dateNow >= liveSessionStart;
      this.liveStreamHasEnded = dateNow >= liveSessionEnd;
      this.liveSessionActive =
          dateNow >= liveSessionStart && dateNow <= liveSessionEnd;
      this.zoomSessionActive =
          dateNow >= liveSessionStart && dateNow <= zoomSessionEnd;
      this.interval = setInterval(() => {
        this.actualTime = moment(this.actualTime).add(5, "seconds");
        dateNow = moment(this.actualTime);
        sessionStart = new Date(session.start_time + this.utcOffset);
        sessionEnd = new Date(session.end_time + this.utcOffset);
        this.sessionHasStarted = dateNow > sessionStart;
        this.sessionHasEnded = dateNow > sessionEnd;

        liveSessionStart = moment(sessionStart).subtract(5, "minutes");
        liveSessionEnd = moment(sessionEnd).add(10, "minutes");
        zoomSessionEnd = moment(sessionEnd).add(10, "minutes");
        this.liveStreamHasStarted = dateNow >= liveSessionStart;
        this.liveStreamHasEnded = dateNow >= liveSessionEnd;
        this.liveSessionActive =
            dateNow >= liveSessionStart && dateNow <= liveSessionEnd;
        this.zoomSessionActive =
            dateNow >= liveSessionStart && dateNow <= zoomSessionEnd;
      }, 5000);
    },

    checkRestriction() {
      if (this.user && this.user.extra && this.user.extra[this.currentConference]) {
        let extra = this.user.extra[this.currentConference];
        //let full = extra.tn_category == 'Full';
        let pfd = extra.tn_category_short == 'pfd';
        let virtsat = extra.tn_category_short == 'virtsat';
        let qm = extra.tn_category_short == 'qm';
        let pfdSessions = ['da7ad0c0-3ed1-4500-1302-010000000027', 'da7ad0c0-3ed1-4500-1302-010000000028', 'da7ad0c0-3ed1-4500-1302-010000000029', 'da7ad0c0-3ed1-4500-1302-010000000030', 'da7ad0c0-3ed1-4500-1302-010000000031'];
        let qmSessions = ['da7ad0c0-3ed1-4500-1302-010000000152', 'da7ad0c0-3ed1-4500-1302-010000000156', 'da7ad0c0-3ed1-4500-1302-010000000158', 'da7ad0c0-3ed1-4500-1302-010000000159'];

        if (pfd && pfdSessions.indexOf(this.session.id) == -1) {
          this.isRestrictedAccess = true;
        }
        if (qm && qmSessions.indexOf(this.session.id) == -1) {
          this.isRestrictedAccess = true;
        }
        if (virtsat && this.session.start_time.indexOf('2022-03-19T') == -1) {
          this.isRestrictedAccess = true;
        }
      }
    }
  },
  async created() {
    this.moment = moment;
       this.isIos = isPlatform('ios')
  },
  watch: {
    '$route': {
      // with immediate handler gets called on first mount aswell
      immediate: true,
      // handler will be called every time the route changes.
      // reset your local component state and fetch the new data you need here.
      async handler(to) {
        if (to.name == 'Session Detail') {

          this.utcOffset = await this.getSetting('timezone');
          this.timezoneString = await this.getSetting('timezone_label');
          console.log('UTC', this.utcOffset, this.timezoneString)
          this.session = await this.getSingleSession(to.params.id);
          this.favId = 'sessions/' + this.session.id;
          this.sessiontype = await this.getSessionType(this.session.id)
          console.log('fav from session', this.favId)
          //this.checkRestriction();
          this.isRestrictedAccess = false;
          if (this.session.location) {
            this.mapAvailable = await this.getMapFromLocation(this.session.location.id);
          }
          this.persons = await this.getSessionPersons(this.session.id);
          this.speakers = await this.getSpeakersInSession(this.session.id);
          let presentations = await this.getPresentationList(this.session.id);
          this.presentations = presentations.sort((a, b) => {
            return a.order - b.order;
          });
          this.loaded = true;

          if (this.persons.length == 0) {
            this.showTab = 'Presentations'
          }

          this.polls = await this.getPollForState(window.location);
          this.trackWithLabel(this.session.title)
          this.getForum(to.params.id);
          let links;
          try {
            let external = this.session.external_link.replace(/'/g, '"');
            links = JSON.parse(external);
          } catch (err) {
            links = this.session.external_link;

          }
          if (links.live && links.live.indexOf('autoplay') != -1) {
            links.live = links.live.replace('autoplay=0', 'autoplay=1')
          }

          this.session.liveLink = links.live;
          this.session.odLink = links.od;

          this.isLiveSession = this.session.location && this.session.location.id != 'da7ad0c0-3ed1-4500-1302-080000000018' ? true : false;
          this.actualTime = await this.getServerTime();
          this.checkTime(this.session);

          let trackData = {
            user: this.user,
            item: this.session,
            event: 'enter',
            type: 'session',
            itemId: this.session.id,
          }
          this.trackEvent(trackData)
        } else {
          this.session.liveLink = null;
          this.stopVideos();
          let trackData = {
            user: this.user,
            item: this.session,
            event: 'leave',
            type: 'session',
            itemId: this.session.id,
          }
          this.trackEvent(trackData)
        }
      }
    }
  }
});
</script>
<style lang="scss">
$main-color:#E30513;
.details-container {
  line-height: 40px;
}

.location-box {
  padding-left: 15px;
  border-left: 0.3rem solid;
}

.type-detail {
  color: grey
}

.location-icon {
  margin-right: 10px;
  color: black !important
}

.location-text {
  text-decoration: none !important;
  color: #000 !important;


}

.interactive-segment {
  --background: #fff;
}

.interactive-segment-btn {
  --background: #fff !important;
  --color: 3 d5a59 !important;
  --color-checked: #3d5a59 !important;
  --background-checked: #fff !important;
}

.session-head {
  display: inline-block;
  border-bottom: none;
  .code-wrapper {
    position: absolute;
    left: 0px;
    height: 100%;
    width: 8px;
    border-radius: 5px;
  }


  .item-header-gradient {
    position: absolute;
    //z-index: -1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: url('../../assets/shadow.png') no-repeat bottom center;
    background-size: cover;
  }

  .title {
    font-size: 1.1rem;
  }

  .type {
    font-size: 0.8rem;
    display: inline-block;
    line-height: 0.8rem;

    strong {
      display: inline-block;
      font-size: 0.7rem;
    }
  }

  .time {
    font-size: 0.8rem;
  }
}


.session-corner-annotation {
  background-image: linear-gradient(to right, rgb(197, 50, 50), rgb(179, 68, 68));
  position: absolute;
  top: -45px;
  right: -115px;
  width: 180px;
  height: 150px;
  -webkit-transform: rotate(45deg);
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.annotation-text {
  position: absolute;
  width: 100%;
  bottom: 0;
  text-align: center;
  color: white;
}

.content-left {
  .action-buttons {
    .actn-icon {
      font-size: 1.3rem !important;
    }

    .actn-text {
      font-size: 0.7rem !important;
      line-height: 0.8rem !important;
    }
  }

  .stream-container {
    width: 100%;
    //max-width: 1100px;

    .stream {
      position: relative;
      width: 100%;
      aspect-ratio: 16/9;

      .info-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 3px dashed grey;

        .info {
          flex: 0 0 400px;
          font-size: 2rem;
          line-height: 2.2rem;
          color: grey;
          font-weight: bold;
        }
      }
    }
  }

  


}

@media(max-width: 767px) {
  .person-item {
    max-width: 100%;
    border-radius: 0;
    border: none;
  }

.description-box {
    font-size: 0.9rem;
    max-height: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .description-button{
    position: relative;
    left: 20px;
    top: 0px;
    background: $main-color;
    border: none;
   // top: 0px;
    //opacity: 40% !important;
  }
}
  @media(min-width: 768px) {
   .description-button{
    display: none !important;
  }
}

</style>
